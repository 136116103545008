$themes: (
  light: (
    background: #f0f0f0,
    background-card: #f5f5f5,
    background-unselected-card: #e1e1e1,
    text: #212121,
    text-secondary: #757575,
    color-shadow: #e1e1e1aa,
  ),
  dark: (
    background: #001f3d,
    background-card: #002952,
    background-unselected-card: #001a33,
    text: #f5f5f5,
    text-secondary: #bdbdbd,
    color-shadow: #001a33aa,
  ),
);

@mixin theme() {
  @each $theme, $map in $themes {
    .#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function get-color($key) {
  @return map-get($theme-map, $key);
}
