.side-nav {
  width: 105px;
  @apply px-5 pb-16 overflow-x-hidden z-50 pt-32 -mt-4;
  @include media-breakpoint-down(md) {
    @apply hidden;
  }

  &.side-nav--simple {
    width: 105px;

    .side-menu {
      .side-menu__title {
        @apply hidden;
        .side-menu__sub-icon {
          @apply hidden;
        }
      }

      &:not(.side-menu--active) .side-menu__icon:before {
        @apply hidden;
      }
    }
  }

  .side-nav__devider {
    @apply w-full h-px bg-white/[0.08] z-10 relative dark:bg-white/[0.07];
  }

  .side-menu {
    @apply h-[50px] flex items-center pl-5 text-slate-600 mb-1 relative rounded-xl dark:text-slate-300;
    .side-menu__title {
      @apply w-full ml-3 flex items-center;
      .side-menu__sub-icon {
        @apply transition ease-in duration-100 ml-auto mr-5;
        svg {
          @apply w-4 h-4;
        }

        @apply hidden;
      }

      @apply hidden;
    }
  }

  > ul {
    @for $i from 1 through 50 {
      > li:nth-child($i) {
        &.side-nav__devider {
          @apply opacity-0;
          animation: 0.4s intro-devider-animation ease-in-out 0.33333s;
          animation-fill-mode: forwards;
          animation-delay: calc($i * 0.1s);
        }

        & > a {
          &:not(.side-menu--active) {
            @apply opacity-0;
            transform: translateX(50px);
            animation: 0.4s intro-menu-animation ease-in-out 0.33333s;
            animation-fill-mode: forwards;
            animation-delay: calc($i * 0.1s);
          }

          &.side-menu--active {
            animation: 0.4s intro-active-menu-animation ease-in-out 0.33333s;
            animation-fill-mode: forwards;
          }
        }
      }
    }

    ul {
      @for $i from 1 through 50 {
        li:nth-child($i) {
          & > a {
            @apply opacity-0;
            transform: translateX(50px);
            animation: 0.2s intro-submenu-animation ease-in-out 0.33333s;
            animation-fill-mode: forwards;
            animation-delay: calc($i * 0.1s);
          }
        }
      }
      @apply hidden bg-white/[0.04] rounded-xl relative dark:bg-transparent;
      &:before {
        content: "";
        @apply block inset-0 bg-white/30 rounded-xl absolute z-[-1] dark:bg-darkmode-900/30;
      }

      &.side-menu__sub-open {
        @apply block;
      }

      .side-menu__icon:before {
        @apply hidden;
      }

      li a {
        &:not(.side-menu--active) {
          @apply text-slate-600 dark:text-slate-400;
        }

        &.side-menu--active {
          .side-menu__icon {
            @apply text-slate-700 dark:text-slate-300;
          }

          .side-menu__title {
            @apply text-slate-700 font-medium dark:text-slate-300;
          }
        }
      }

      ul {
        @apply hidden bg-white/[0.04] rounded-xl relative dark:bg-transparent;
        &:before {
          content: "";
          @apply block inset-0 bg-white/30 rounded-xl absolute z-[-1] dark:bg-darkmode-900/30;
        }
      }
    }

    & > li {
      & > .side-menu {
        &.side-menu--active {
          @apply bg-slate-100 dark:bg-transparent;
          &:before {
            content: "";
            @apply block inset-0 rounded-xl absolute border-b-[3px] border-solid border-black/[0.08] dark:bg-darkmode-700;
          }

          &:after {
            content: "";
            width: 20px;
            height: 80px;
            margin-right: -47px;
            animation: 0.3s active-side-menu-chevron-animation ease-in-out 0.33333s;
            animation-fill-mode: forwards;
            animation-delay: 1s;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23f1f5f8'/%3E%3C/svg%3E");
            @apply bg-no-repeat bg-cover absolute top-0 bottom-0 right-0 my-auto opacity-0;
          }

          .side-menu__icon {
            @apply text-primary z-10 dark:text-slate-300;
          }

          .side-menu__title {
            @apply text-primary font-medium z-10 dark:text-slate-300;
          }
        }

        .side-menu__icon {
          @apply dark:text-slate-400;
        }

        .side-menu__title {
          @apply dark:text-slate-400;
        }

        &:hover:not(.side-menu--active):not(.side-menu--open) {
          @apply bg-slate-100 dark:bg-transparent;
          &:before {
            content: "";
            @apply block inset-0 rounded-xl absolute z-[-1] border-b-[3px] border-solid border-black/[0.08] dark:bg-darkmode-700;
          }
        }
      }
    }
  }
}

@keyframes intro-devider-animation {
  100% {
    @apply opacity-100;
  }
}

@keyframes intro-menu-animation {
  100% {
    @apply opacity-100;
    transform: translateX(0px);
  }
}

@keyframes intro-submenu-animation {
  100% {
    @apply opacity-100;
    transform: translateX(0px);
  }
}

@keyframes intro-active-menu-animation {
  100% {
    @apply z-10;
  }
}

@keyframes active-side-menu-chevron-animation {
  100% {
    margin-right: -27px;
    @apply opacity-100;
  }
}

.dark {
  .side-nav {
    > ul {
      & > li {
        & > .side-menu {
          &.side-menu--active {
            &:after {
              background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23232e45'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }
  }
}
