.top-nav {
    transform: translatey(35px);
    animation: 0.4s intro-top-menu-animation ease-in-out 0.33333s;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
    @apply z-50 relative opacity-0 pt-32 -mt-4;
    @include media-breakpoint-down(md) {
        @apply hidden;
    }
    > ul {
        @for $i from 1 through 50 {
            > li:nth-child($i) {
                & > a {
                    &:not(.top-menu--active) {
                        @apply opacity-0;
                        transform: translateY(50px);
                        animation: 0.4s
                            intro-menu-animation
                            ease-in-out
                            0.33333s;
                        animation-fill-mode: forwards;
                        animation-delay: calc($i * 0.1s);
                    }
                    &.top-menu--active {
                        animation: 0.4s
                            intro-active-menu-animation
                            ease-in-out
                            0.33333s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
        padding: 0px 50px;
        @apply flex flex-wrap;
        @include media-breakpoint-down(xl) {
            @apply px-6;
        }
        > li > .top-menu {
            @apply mt-[3px];
            > .top-menu__icon {
                @apply -mt-[3px];
            }
            > .top-menu__title {
                @apply -mt-[3px];
            }
        }
        li {
            @apply relative;
            &:hover {
                > .top-menu > .top-menu__title > .top-menu__sub-icon {
                    @apply rotate-180;
                }
                > ul {
                    @apply block;
                    li:hover
                        .top-menu
                        > .top-menu__title
                        > .top-menu__sub-icon {
                        @apply -rotate-90;
                    }
                }
            }
            .top-menu {
                height: 55px;
                @apply flex items-center px-5 mr-1 text-slate-600 relative rounded-xl;
                @include media-breakpoint-down(xl) {
                    @apply rounded-full;
                }
                &.top-menu--active {
                    @apply bg-slate-100 text-primary dark:bg-darkmode-700;
                    &:before {
                        content: "";
                        @apply block inset-0 rounded-xl absolute border-b-[3px] border-solid border-black/[0.08] dark:bg-darkmode-700;
                        @include media-breakpoint-down(xl) {
                            @apply hidden;
                        }
                    }
                    &:after {
                        content: "";
                        width: 20px;
                        height: 80px;
                        margin-bottom: -74px;
                        animation: 0.3s active-top-menu-chevron-animation
                            ease-in-out 0.33333s;
                        animation-fill-mode: forwards;
                        animation-delay: 1s;
                        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23f1f5f8'/%3E%3C/svg%3E");
                        @apply bg-no-repeat bg-cover absolute left-0 right-0 bottom-0 mx-auto transform rotate-90 opacity-0;
                        @include media-breakpoint-down(xl) {
                            @apply hidden;
                        }
                    }
                    .top-menu__icon {
                        @apply dark:text-white;
                        @include media-breakpoint-down(xl) {
                            @apply text-primary;
                        }
                    }
                    .top-menu__title {
                        @apply font-medium dark:text-white;
                        @include media-breakpoint-down(xl) {
                            @apply text-slate-800;
                        }
                    }
                }
                .top-menu__icon {
                    @apply z-10 dark:text-slate-400;
                }
                .top-menu__title {
                    @apply z-10 dark:text-slate-400;
                }
                .top-menu__title {
                    @apply ml-3 flex items-center whitespace-nowrap;
                    .top-menu__sub-icon {
                        @apply transition ease-in duration-100 w-4 h-4 ml-2;
                        @include media-breakpoint-down(xl) {
                            @apply hidden;
                        }
                    }
                }
            }
            ul {
                box-shadow: 0px 3px 20px #00000014;
                @apply bg-slate-100 hidden w-56 absolute rounded-md z-20 px-0 mt-1 before:block before:absolute before:w-full before:h-full before:bg-white/[0.04] before:inset-0 before:rounded-md before:z-[-1] dark:before:bg-black/10;
                &:after {
                    content: "";
                    @apply w-full h-1 absolute top-0 left-0 -mt-1 cursor-pointer;
                }
                li {
                    @apply px-5 relative;
                    .top-menu {
                        @apply px-0 mr-0;
                        .top-menu__icon:before {
                            @apply hidden;
                        }
                        .top-menu__title {
                            @apply w-full;
                            .top-menu__sub-icon {
                                @apply ml-auto;
                            }
                        }
                    }
                }
                ul {
                    left: 100%;
                    @apply bg-slate-100 hidden rounded-md mt-0 ml-0 top-0 before:block before:absolute before:w-full before:h-full before:bg-white/[0.04] before:inset-0 before:rounded-md before:z-[-1] dark:before:bg-black/10;
                }
            }
        }
        > li:hover > .top-menu:not(.top-menu--active) {
            @apply bg-slate-100 dark:bg-transparent;
            &:before {
                content: "";
                @apply block inset-0 rounded-xl absolute z-[-1] border-b-[3px] border-solid border-black/[0.08] dark:bg-darkmode-700;
                @include media-breakpoint-down(xl) {
                    @apply rounded-full;
                }
            }
        }
    }
}

@keyframes intro-top-menu-animation {
    100% {
        @apply opacity-100;
        transform: translateY(0px);
    }
}

@keyframes intro-active-top-menu-animation {
    100% {
        @apply z-10;
    }
}

@keyframes active-top-menu-chevron-animation {
    100% {
        margin-bottom: -54px;
        @apply opacity-100;
    }
}

.dark {
    .top-nav {
        > ul {
            li {
                .top-menu {
                    &.top-menu--active {
                        &:after {
                            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='80' viewBox='0 0 20 122.1'%3E%3Cpath data-name='Union 1' d='M16.038 122H16v-2.213a95.805 95.805 0 00-2.886-20.735 94.894 94.894 0 00-7.783-20.434A39.039 39.039 0 010 61.051a39.035 39.035 0 015.331-17.567 94.9 94.9 0 007.783-20.435A95.746 95.746 0 0016 2.314V0h4v122h-3.961v.1l-.001-.1z' fill='%23232e45'/%3E%3C/svg%3E");
                        }
                    }
                }
                ul {
                    @apply bg-darkmode-600;
                }
            }
            ul {
                box-shadow: 0px 3px 7px #0000001c;
            }
        }
    }
}
