.search {
    @apply relative;
    .search__input {
        @apply w-56 shadow-none rounded-full bg-slate-200 pr-8 transition-[width] duration-300 ease-in-out focus:border-transparent dark:bg-darkmode-400;
        &:focus {
            @apply w-72;
        }
    }
    .search__icon {
        @apply w-5 h-5 absolute my-auto inset-y-0 mr-3 right-0 text-slate-600;
    }
}
