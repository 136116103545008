.top-bar-boxed {
  &.top-bar-boxed--simple-menu {
    .logo {
      @apply w-auto;
      .logo__text {
        @apply hidden;
      }
    }
  }

  &.top-bar-boxed--top-menu {
    @apply dark:md:from-darkmode-800;
    .logo {
      @apply w-auto;
    }

    .breadcrumb {
      @apply md:ml-10 md:pl-10;
    }
  }

  &:not(.top-bar-boxed--top-menu) {
    .logo__text {
      @apply hidden xl:block;
    }
  }

  &:before {
    content: "";
    @apply absolute h-[65px] inset-0 top-0 mx-7 bg-primary/30 mt-3 rounded-xl hidden md:block dark:bg-darkmode-600/30;
  }

  &:after {
    content: "";
    @apply absolute inset-0 h-[65px] mx-3 bg-primary mt-5 rounded-xl shadow-md hidden md:block dark:bg-darkmode-600;
  }
}
