.chat-dropdown {
    @apply w-[320px] -mt-[364px];
    .chat-dropdown__box {
        @apply h-[364px];
        .chat-dropdown__box__tabs {
            .nav-item .nav-link {
                &.active {
                    @apply bg-slate-200 border-b-0 text-slate-600 dark:bg-darkmode-300 dark:text-slate-300;
                }
            }
        }
    }
}
