@import "./styles/color-themes";
@import "./styles/font-size";

.error-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .error-image {
    height: 240px;
  }

  .error-title {
    @include theme() {
      color: get-color("text");
    }
    font-family: "Barlow", sans-serif;
    font-size: $subtitle2-font-size;
    font-weight: 700;
  }

  .error-message {
    @include theme() {
      color: get-color("text");
    }
    font-family: "Barlow", sans-serif;
    font-size: $caption-font-size;
  }
}
