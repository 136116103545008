.chat {
  .chat__box {
    .chat__box__text-box {
      @apply max-w-[80%];
      @include media-breakpoint-down(sm) {
        @apply max-w-[90%];
      }
    }
  }

  .chat__box__input {
    @apply h-[46px];
  }
}
