.table-report {
    &:not(.table-report--bordered):not(.table-report--tabulator) {
        border-spacing: 0 10px;
        @apply border-separate;
        th {
            @apply border-b-0;
        }
        td {
            @apply bg-white border-b-0 dark:bg-darkmode-600;
            box-shadow: 20px 3px 20px #0000000b;
            &:first-child {
                @apply rounded-l-md;
            }
            &:last-child {
                @apply rounded-r-md;
            }
            &.table-report__action {
                @apply py-0 relative before:block before:w-px before:h-8 before:bg-slate-200 before:absolute before:left-0 before:inset-y-0 before:my-auto before:dark:bg-darkmode-400;
            }
        }
    }
    img {
        box-shadow: 0px 0px 0px 2px #fff, 1px 1px 5px rgba(0, 0, 0, 0.32);
    }
    &.table-report--tabulator
        .tabulator-header
        .tabulator-headers
        .tabulator-col {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            .tabulator-col-content {
                .tabulator-col-title {
                    @apply text-center;
                }
            }
        }
    }
}

.dark {
    .table-report {
        img {
            box-shadow: 0px 0px 0px 2px #3f4865, 1px 1px 5px rgba(0, 0, 0, 0.32);
        }
    }
}
