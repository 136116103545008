.report-box-4 {
    @apply relative;
    &:before {
        content: "";
        border-radius: 30px 30px 0px 0px;
        transform: translatey(35px);
        animation: 0.4s intro-report-box-4-animation ease-in-out 0.33333s;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
        margin-left: -22px;
        margin-right: -22px;
        box-shadow: 0px 3px 20px #0000000b;
        @apply h-full bg-slate-100 absolute top-0 left-0 right-0 opacity-0 hidden dark:bg-darkmode-700;
        @include media-breakpoint-down(xl) {
            @apply block;
        }
        @include media-breakpoint-down(md) {
            margin-left: -16px;
            margin-right: -16px;
        }
    }
    .report-box-4__content {
        flex: 1 1 0;
    }
}

@keyframes intro-report-box-4-animation {
    100% {
        @apply opacity-100;
        transform: translateX(0px);
    }
}
